<template>
  <div>
    <input type="text" placeholder="输入用户名" v-model="username" />
    <button @click="handleEnter">进入</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
    };
  },
  mounted(){
    const username = localStorage.getItem('username');
    if(username){
      this.$router.push('/')
    }
  },
  methods: {
    handleEnter() {
      const username = this.username.trim();
      if(username.length<6){
        alert('用户名不小于6位')
        return ;
      }
      localStorage.setItem('username',username)
      this.$router.push('/')
    },
  },
};
</script>

<style></style>
