import Vue from 'vue'
import App from './App.vue'
import router from './router'
import httpRequest from '@/utils/httpRequest'

Vue.config.productionTip = false
Vue.prototype.$http = httpRequest // ajax请求方法

import Toasted from 'vue-toasted';

// or you can use it with require
// var Toasted = require('vue-toasted').default

Vue.use(Toasted)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
